<template>
  <div class="content">
    <h1>Datenschutzerklärung</h1>
    Die nachfolgende Datenschutzerklärung gilt für die Nutzung unseres Online-Angebots [www.jtk-neun<wbr>kirchen.de] (nachfolgend „Website“).
    Wir messen dem Datenschutz große Bedeutung bei. Die Erhebung und Verarbeitung Ihrer personen<wbr>bezogenen Daten geschieht unter Beachtung der geltenden daten<wbr>schutz<wbr>recht<wbr>lichen Vor<wbr>schriften, insbesondere der Daten<wbr>schutz<wbr>grund<wbr>verordnung (DSGVO).

    <ol type="1">
      <li><h2>Verantwortlicher</h2>
        <p class="block">
          Verantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist
          St. Elisabethenverein e. V., Mühlwiesenweg 20, 91077 Neunkirchen am Brand
          vertreten durch
          Pfarrer Joachim Cibura, 1. Vorsitzender, Kirchplatz 4, 91077 Neunkirchen am Brand
          Email: info@elisabethenverein-nk.de
          Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch an den Verantwortlichen richten. Sie können diese Datenschutzerklärung jederzeit speichern und ausdrucken.
        </p>
      </li>

      <li>
        <h2>Allgemeine Zwecke der Verarbeitung</h2>
        <p>Wir verwenden personenbezogene Daten zum Zweck des Betriebs der Website.</p>
      </li>

      <li>
        <h2>Welche Daten wir verwenden und warum</h2>
        <ol>
          <li>
            <h2>Hosting</h2>
            <p>
              Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen. Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website gem. Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO.
            </p>
          </li>

          <li>
            <h2>Zugriffsdaten</h2>
            <p>
              Wir sammeln Informationen über Sie, wenn Sie diese Website nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Website (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:
            </p>
            <ul>
              <li>Name und URL der abgerufenen Datei</li>
              <li>Datum und Uhrzeit des Abrufs</li>
              <li>übertragene Datenmenge</li>
              <li>Meldung über erfolgreichen Abruf (HTTP response code)</li>
              <li>Browsertyp und Browserversion</li>
              <li>Betriebssystem</li>
              <li>Referer URL (d.h. die zuvor besuchte Seite)</li>
              <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
              <li>Internet-Service-Provider des Nutzers</li>
              <li>IP-Adresse und der anfragende Provider</li>
            </ul>
            <p>
              Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern. Hierin liegt auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO. Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. IP-Adressen speichern wir für einen begrenzten Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke erforderlich oder für die Leistungserbringung nötig ist. Nach Abbruch des Vorgangs löschen wir die IP-Adresse, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist. IP-Adressen speichern wir auch dann, wenn wir den konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben. Außerdem speichern wir als Teil Ihres Accounts das Datum Ihres letzten Besuchs (z.B. bei Registrierung, Login, Klicken von Links etc.)
            </p>
          </li>

          <li>
            <h2>Cookies</h2>
            <p>
              Wir verwenden sogenannte Session-Cookies, um unsere Website zu optimieren. Ein Session-Cookie ist eine kleine Textdatei, die von den jeweiligen Servern beim Besuch einer Internetseite verschickt und auf Ihrer Festplatte zwischengespeichert wird. Diese Datei als solche enthält eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Diese Cookies werden gelöscht, nachdem Sie Ihren Browser schließen. Sie dienen z.B. dazu, um Funktionen der Website über mehrere Seiten hinweg zu nutzen. Wir verwenden in geringem Umfang auch persistente Cookies (ebenfalls kleine Textdateien, die auf Ihrem Endgerät abgelegt werden), die auf Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit von allein. Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre. So können wir Ihnen unser Angebot nutzerfreundlicher, effektiver und sicherer präsentieren. Unser berechtigtes Interesse an der Nutzung der Cookies gemäß Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Website nutzerfreundlicher, effektiver und sicherer zu machen. In den Cookies werden etwa folgende Daten und Informationen gespeichert:
            </p>
            <ul>
              <li>Log-In-Informationen</li>
              <li>Spracheinstellungen</li>
              <li>eingegebene Suchbegriffe</li>
              <li>Informationen über die Anzahl der Aufrufe unserer Website sowie Nutzung einzelner Funktionen unseres Internetauftritts.</li>
            </ul>
            <p>
              Bei Aktivierung des Cookies wird diesem eine Identifikationsnummer zugewiesen und eine Zuordnung Ihrer personenbezogenen Daten zu dieser Identifikationsnummer wird nicht vorgenommen. Ihr Name, Ihre IP-Adresse oder ähnliche Daten, die eine Zuordnung des Cookies zu Ihnen ermöglichen würden, werden nicht in den Cookie eingelegt. Auf Basis der Cookie-Technologie erhalten wir lediglich pseudonymisierte Informationen, beispielsweise darüber, welche Seiten unserer Website Sie zuletzt besucht haben. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Website eingeschränkt werden.
            </p>
          </li>

          <li>
            <h2>Nutzerkonto</h2>
            <p>
              Sie können auf unserer Website ein Nutzerkonto anlegen. Wünschen Sie dies, so benötigen wir die beim Login abgefragten personenbezogenen Daten. Beim späteren Einloggen werden nur Ihre Email bzw. Benutzername und das von Ihnen gewählte Passwort benötigt. Für die Neuregistrierung erheben wir Stammdaten (z. B. Name, Adresse), Kommunikationsdaten (z. B. E-Mail-Adresse) und Zugangsdaten (Benutzername u. Passwort). Um Ihre ordnungsgemäße Anmeldung sicherzustellen und unautorisierte Anmeldungen durch Dritte zu verhindern, werden nur Accounts von Vereinsmitgliedern persönlich von Administratoren freigeschalten. Erst nach erfolgter Registrierung speichern wir die von Ihnen übermittelten Daten dauerhaft in unserem System. Sie können ein einmal angelegtes Nutzerkonto jederzeit von uns löschen lassen. Eine Mitteilung in Textform an die unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür aus. Wir werden dann Ihre gespeicherten personenbezogenen Daten löschen, soweit wir diese nicht noch zur Abwicklung von Bestellungen oder aufgrund gesetzlicher Aufbewahrungspflichten speichern müssen. Rechtgrundlage für die Verarbeitung dieser Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO.
            </p>
          </li>

          <li>
            <h2>E-Mail Kontakt</h2>
            <p>
              Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail), verarbeiten wir Ihre Angaben zur Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen. Erfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, bzw., wenn Sie bereits unser Kunde sind, zur Durchführung des Vertrages, ist Rechtsgrundlage für diese Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO. Weitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder wir ein berechtigtes Interesse an der Verarbeitung Ihrer Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO). Ein berechtigtes Interesse liegt z. B. darin, auf Ihre E-Mail zu antworten.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Speicherdauer</h2>
        <p>
          Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist. In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.
        </p>
      </li>

      <li>
        <h2>Datensicherheit</h2>
        <p>
          Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht. Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen. Wir gewährleisten außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmäßig sorgfältig gesichert.
        </p>
      </li>

      <li>
        <h2>Ihre Rechte als von der Datenverarbeitung Betroffener</h2>
        <p>
          Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 1 genannte Adresse. Nachfolgend finden Sie eine Übersicht über Ihre Rechte.
        </p>
        <ol>
          <p>
            Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
          </p>
          <li>
            <h2>Recht auf Bestätigung und Auskunft</h2>
            <p>
              Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten. Im Einzelnen: Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:
              1. die Verarbeitungszwecke;
              2. die Kategorien personenbezogener Daten, die verarbeitet werden;
              3. die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
              4. falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
              5. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
              6. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
              7. wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
              8. das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
            </p>
          </li>

          <li>
            <h2>Recht auf Berichtigung</h2>
            <p>
              Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen. Im Einzelnen: Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
            </p>
          </li>

          <li>
            <h2>Recht auf Löschung ("Recht auf Vergessenwerden")</h2>
            <p>
              In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen. Im Einzelnen: Sie haben gemäß Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
              <br>
              1. Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
              2. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
              3. Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
              4. Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
              5. Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
              6. Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben. Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir gemäß Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
            </p>
          </li>

          <li>
            <h2>Recht auf Einschränkung der Verarbeitung</h2>
            <p>
              In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Im Einzelnen: Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist: 1. die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen, 2. die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben; 3. wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder 4. Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Vereins gegenüber den Ihren überwiegen.
            </p>
          </li>

          <li>
            <h2>Recht auf Datenübertragbarkeit</h2>
            <p>
              Sie haben das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu lasen. Im Einzelnen: Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern 1. die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 S. 1 b) DSGVO beruht und 2. die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
            </p>
          </li>

          <li>
            <h2>Widerspruchsrecht</h2>
            <p>
              Sie haben das Recht, aus einer rechtmäßigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen. Im Einzelnen: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
            </p>
          </li>

          <li>
            <h2>Automatisierte Entscheidungen einschließlich Profiling</h2>
            <p>
              Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Eine automatisierte Entscheidungsfindung auf der Grundlage der erhobenen personenbezogenen Daten findet nicht statt.
            </p>
          </li>

          <li>
            <h2>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h2>
            <p>
              Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
            </p>
          </li>

          <li>
            <h2>Recht auf Beschwerde bei einer Aufsichtsbehörde</h2>
            <p>
              Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Weitergabe von Daten an Dritte, keine Datenübertragung ins Nicht-EU-Ausland</h2>
        <p>
          Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Vereins. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten, erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist. Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten. Eine Datenübertragung an Stellen oder Personen außerhalb der EU außerhalb des in dieser Erklärung in Ziffer 4 genannten Falls findet nicht statt und ist nicht geplant.
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Datenschutz"
}
</script>

<style scoped>
ol {
  counter-reset: item;
}
ol > li {
  display: block;
}
ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin-left: -20px;
}

pl > li h1, h2, h3, h4 {
  display: inline;
}
</style>